// Here you can add other styles
@import "variables";

input:focus,
textarea:focus,
select:focus,
a:focus {
	outline: none !important;
}

.avatar {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: $white;
	box-shadow: $box-shadow-sm;
	font-size: 18px;
	font-weight: 300;
	color: $gray-600;
}

// Sidebar

.sidebar {
  @include media-breakpoint-down(sm) {
    --cui-sidebar-width: 100%;
  }

	.nav-title {
		letter-spacing: 1.5px;
		font-weight: $font-weight-medium;
	}
	.nav-link {
		font-weight: $font-weight-semibold;
	}
  .nav-group-items .nav-icon {
    display: none;
  }

  .avatar {
    width: 30px;
    height: 30px;
  }

  .sidebar-header .btn-close {
    position: absolute;
    top: 8px;
    right: 6px;
  }
}

.sidebar.sidebar-narrow-unfoldable:not(:hover) .avatar-info,
.sidebar.sidebar-narrow-unfoldable:not(:hover) .avatar-caret {
	display: none !important;
}

body:has(.page-public) {
  #root {
    background-color: #141416;
    background-image: url("../../public/img/bg-hex-dark.svg");
    min-height: 100vh;
    display: flex;
    align-items: stretch;
    position: relative;

    &:before {
      content: ' ';
      position: absolute;
      width: 50%;
      height: 100%;
      right: 50px;
      background-image: url("../../public/img/login_blue.png");
      background-size: 100%;
      background-position: center right;
      background-repeat: no-repeat;

      @include media-breakpoint-down(md) {
        background-image: none;
      }
    }
  }
}

.page-public {
  align-self: center;

  > div {
    position: relative;
    z-index: 2;
  }

  h4 span {
    color: #b3caf2;
  }

  @include media-breakpoint-up(lg) {
    .container {
      max-width: 680px;
    }
  }
}

.page:has(.sidebar) {
  .page-wrapper {
    max-width: calc(100% - var(--cui-sidebar-occupy-start));
  }
}

// Buttons

.btn {
  box-shadow: none;
  font-weight: bold;

  &.active {
    // to prevent background flickering on variants
    background-color: transparent;
  }

  &:hover, &:active, &:first-child:active, &.active, &:disabled {
    border-color: transparent;
    box-shadow: none;
  }
}

.btn-primary {
  --cui-btn-bg: var(--cui-primary);
  --cui-btn-border-color: var(--cui-primary);

  &:hover {
    background-image: $primary-linear-gradient;
  }

  &:active, &:first-child:active {
    background-color: var(--cui-secondary);
  }

  &:disabled, &.disabled {
    background-color: $gray-500;
    background-image: none;
  }
}

.btn-ghost-primary {
  color: $gray-600;

  &:hover {
    color: var(--cui-primary);
    background-color: transparent;
  }

  &:active {
    background-color: transparent !important;
    color: var(--cui-primary) !important;

    border: solid 1px transparent;
    border-color: transparent !important;
    background-image: linear-gradient(white, white), radial-gradient(circle at top left, var(--cui-primary), var(--cui-secondary)) !important;
    background-origin: border-box;
    background-clip: padding-box, border-box;
  }

  &.active {
    border-color: transparent;
    color: var(--cui-primary);
    background-image: $secondary-linear-gradient;
  }

  &:disabled, &.disabled {
    color: $gray-500;
  }
}

// Forms
.input-group {
  .form-control, .form-select, .form-multi-select-input-group {
    border-left: 0 none;
  }

  .form-multi-select {
    flex: 1 1 auto;

    .form-multi-select-input-group {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .input-group-text {
    background-color: $white;

    .icon {
      color: var(--cui-secondary-color);
    }
  }

  &:has(.form-control:focus) {
    .input-group-text {
      border-color: $primary;
    }
  }
}

.form-upload:not(:last-child) {
  border-bottom: none !important;
}

.tenant-selector {
  --cui-form-multi-select-indicator-width: 16px;
  --cui-form-multi-select-indicator-height: 16px;
  --cui-form-multi-select-indicator-icon-size: 16px;
  width: 100%;
  max-width: 110px;
  height: 32px;
  border-bottom: 1px solid $gray-300;

  &:hover {
    border-color: $primary;
  }

  .form-multi-select-input-group {
    border: none;
    box-shadow: none !important;
    background-color: transparent !important;
  }

  .form-multi-select-selection {
    padding: 0;
  }

  .form-multi-select-search {
    width: 100%;
    text-overflow: ellipsis;

    &::placeholder {
      font-size: 16px;
      font-weight: 600;
      color: $gray-900;
    }
  }

  .form-multi-select-buttons {
    min-height: 32px;
    padding-right: 0;
  }

  .form-multi-select-options {
    padding: 8px;
    overflow: auto !important;
  }

  .form-multi-select-option {
    padding-left: 8px;
    padding-right: 8px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .form-multi-select-dropdown {
    left: auto !important;
    right: 0 !important;
    max-width: 198px;
  }
}

.form-switch {
  padding-left: 0;
  display: flex;
  margin: 0;
  min-height: auto;

  .form-check-input {
    height: 1.43em;
    margin: 0;

    &:focus {
      border-color: var(--cui-border-color);
    }
  }
}

// Align form switch with other form elements
label + .form-switch {
  margin-top: 18px;
}

.form-multi-select-with-icon {
  position: relative;

  .icon-placeholder {
    $size: 16px;

    position: absolute;
    top: 0;
    left: 0;
    width: $size;
    height: $size;
    display: flex;
    align-items: center;
    margin: 12px 0 0 12px;
    z-index: 2;

    img, .icon {
      flex-shrink: 0;
    }
  }

  &:has(> .icon-placeholder) {
    .form-multi-select-selection {
      padding-left: 38px;
    }
  }

  .form-multi-select-option {
    white-space: nowrap;
    padding: 4px 8px;

    img, .icon {
      margin-right: 8px;
    }
  }
}

// Tables

table.table-selectable {
  th {
    color: $gray-600;
  }

  th:first-child {
    width: 30px;
  }
  th:has(svg) {
    .d-inline {
      @extend .text-primary-gradient
    }
  }

  thead tr:nth-child(2) {
    background: $gray-150;

    th {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
}

table.field-mapping-overview td.pointer:hover {
  background: $gray-100;
}

.card {
  &.disabled {
    position: relative;
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($white, .5);
    }
  }

  .table-bordered {
    thead tr {
      border-top: none;
    }

    tbody tr:last-child {
      border-bottom: none;
    }

    th:first-child, td:first-child {
      border-left: none;
    }

    th:last-child, td:last-child {
      border-right: none;
    }
  }

  .table-items-per-page-select {
    div:first-child div.row:empty {
      display: none;
    }

    /* single item with no pagination: remove border bottom */
    &:not(:has(> div.row)) {
      tbody tr:last-child td {
        border-bottom-width: 0;
      }
    }

    > div.row:last-child {
      padding-top: 1rem;
    }

    div.row:last-child {
      align-items: center;

      .col-auto {
        padding-right: 1.25rem;
        color: $gray-500;
        text-transform: uppercase;
        font-size: 12px;

        .row {
          margin-bottom: 16px;
        }

        .form-label {
          padding-right: 0;
        }
      }

      .pagination {
        margin-left: 1rem;
        font-weight: 600;
      }
    }

    table.table {
      margin-bottom: 0;
    }
  }

  .spinner-backdrop {
    border-radius: var(--cui-card-border-radius);
  }
}

// Pagination
.page-item {
  .page-link {
    border-radius: var(--cui-pagination-border-radius);
    cursor: pointer;
  }

  &.active .page-link {
    background-image: $secondary-linear-gradient;
  }
}

.col-sticky {
  position: sticky;
  left: 0;

  &:after {
    content: '';
    position: absolute;
    height: 100%;
    top: 0;
    right: -15px;
    width: 15px;
    box-shadow: 15px 0 15px -15px rgba($black, .08) inset;
  }
}

tbody tr:last-child .col-sticky {
  border-bottom-left-radius: var(--cui-border-radius);
}

// Text Gradients

.text-primary-gradient {
  background-image: $primary-linear-gradient;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

// Background Gradients

.bg-secondary-gradient {
  background-color: transparent;
  background-image: $secondary-linear-gradient;
}

// Border Gradients
.border-primary-gradient {
  border: solid 1px transparent;
  background-image: linear-gradient(white, white), radial-gradient(circle at top left, var(--cui-primary), var(--cui-secondary)) !important;
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

// Borders

.rounded-50 {
  border-radius: 50% !important;
}

// Badges

.badge {
  line-height: 16px;
}

.badge-api-method {
  width: 42px;

  &-get {
    background-color: #2f8132;
  }

  &-post {
    background-color: #186faf;
  }

  &-patch {
    background-color: #bf581d;
  }

  &-delete {
    background-color: #cc3333;
  }
}

// Icons

.round-icon-wrapper {
  flex: none;
  display: flex;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50px;
  box-shadow: 0 0 0 0.5px rgba(220, 226, 234, 0.20), 0 1.5px 4px -1px rgba(0, 0, 0, 0.16);
  position: relative;
  z-index: 2;
}

// Cursor

.pointer {
  cursor: pointer;
}

// Schemas

.schema-fields-toggle:hover {
  background: $gray-200 !important;
  transition: background-color 0.15s ease-in-out 0s;
}

.schema-fields-border {
  background-image: linear-gradient($gray-200, $gray-200);
  background-size: 1px 100%;
  background-repeat: no-repeat;
  background-position: 44px top;

  &.schema-fields-border-bottom-half {
    background-size: 1px 50%;
    background-position: 44px bottom;
  }

  .row:not(:first-child) .col-auto.border-top {
    border-top: none !important;
  }
}

// Colors

.bg-gray-150 {
  background-color: $gray-150;
}

.bg-gray-200 {
  background-color: $gray-200;
}

.bg-gray-300 {
  background-color: $gray-300;
}

.bg-gray-700 {
  background-color: $gray-700;
}

.gray-600 {
  color: $gray-600;
}

// Modal

.modal-footer {
  padding: $spacer $modal-inner-padding $modal-inner-padding;
  justify-content: start;

  > * {
    margin: 0;
  }
}

// json-path-selector

#json-path-selector {
  overflow: hidden;
  position: relative;

  > ul {
    margin: 0 !important;
  }

  label span.selected {
    &:before {
      content: '';
      position: absolute;
      left: -100%;
      height: 24px;
      width: 200%;
      margin-top: -2px;
      background: rgba($white, .25);
      z-index: 1;
    }

    &.ai-selected:after {
      content: '';
      z-index: 2;
      position: absolute;
      right: 10px;
      width: 81px;
      height: 20px;
      background-image: url("../../public/img/ai-suggested-label.svg");
      background-repeat: no-repeat;
      background-position: right;
    }
  }
}

.json-tree-wrapper {
  > ul {
    margin: 0 !important;
    padding: 10px 0 !important;
    border-radius: .625rem;
  }
}

.primary-pulse-animation {
  animation: primary-pulse-animation 1.5s 5 ease-in-out;
}

@keyframes primary-pulse-animation {
  0% {
    background: rgba(var(--cui-primary-rgb), 0.3);
    box-shadow: 0 0 0 0 rgba(var(--cui-primary-rgb), 0.3);
  }

  70% {
    background: rgba(var(--cui-primary-rgb), 0);
    box-shadow: 0 0 0 10px rgba(var(--cui-primary-rgb), 0);
  }

  100% {
    background: rgba(var(--cui-primary-rgb), 0);
    box-shadow: 0 0 0 0 rgba(var(--cui-primary-rgb), 0);
  }
}

.arrow-box {
  position: relative;
  border: var(--cui-border-width) solid var(--cui-border-color);

  &:before, &:after {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }


  &:before {
    border-color: rgba($white, 0);
    border-bottom-color: var(--cui-border-color);
    border-width: 12px;
    margin-left: -12px;
  }

  &:after {
    border-color: rgba($white, 0);
    border-bottom-color: $white;
    border-width: 11px;
    margin-left: -11px;
  }
}

.invert-grayscale {
  filter: invert(1) grayscale(100)
}

.max-vh-100 {
  max-height: 100vh;
}

.position-absolute-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner-backdrop {
  top: 0;
  left: 0;
}

// AI Agents

.ai-agent-group {
  .ai-agent {
    width: 150px;

    &-new .ai-agent-icon-placeholder {
      background-image: none;
      background-color: $body-secondary-color;
    }
  }

  .ai-agent-icon-placeholder {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-image: $primary-linear-gradient;
  }
}
