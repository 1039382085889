@import "variables";

.chart-control.form-multi-select {
  width: 94px;

  .form-multi-select-options {
    --cui-form-multi-select-options-padding-y: .5rem;
    --cui-form-multi-select-options-padding-x: .5rem;
  }

  .form-multi-select-input-group {
    border: 0;
    font-size: 12px;
    box-shadow: none !important;
    background-color: transparent !important;
  }

  .form-multi-select-search {
    width: 100%;
    text-overflow: ellipsis;
  }

  .form-multi-select-selection {
    padding: 0;
  }

  .form-multi-select-indicator {
    --cui-form-multi-select-indicator-width: 16px;
    --cui-form-multi-select-indicator-height: 16px;
  }

  .form-multi-select-buttons {
    min-height: auto;
    padding: 0 4px;
  }

  .form-multi-select-option {
    white-space: nowrap;
    font-size: 12px;
    padding: 4px 8px;
  }
}

.dashboard-chart {
  &:not(.dashboard-chart-detailed-view) {
    min-height: 260px;
  }

  .form-multi-select-with-icon {
    max-width: 126px;

    .icon-placeholder {
      width: 16px;
      height: 16px;
      margin: 0;

      img, .icon {
        margin-top: 1px;
      }
    }

    .form-multi-select {
      width: 101%;
    }

    .form-multi-select-selection {
      padding-left: 20px;
    }
  }

  .diff-percentage {
    padding: 2px 4px;
    border-radius: 4px;

    &.text-success {
      background-color: rgba($success, .15);
    }

    &.text-danger {
      background-color: rgba($danger, .15);
    }
  }
}

.logs-chart {
  min-height: 112px !important;
}
