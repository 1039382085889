@import "variables";

// Flow Editor

.flow-editor {
  background-color: #FAFAFA;
  background-image: url("../../public/img/bg-connector-small-light.svg");
  padding: 28px;
}

.flow-editor-node {
  margin-top: 28px;
}

.flow-editor-container {
  > .flow-editor-node:first-child {
    margin-top: 0;
  }
}

.flow-editor-multiple-nodes-in-a-row {
  .flow-editor-node {
    margin-top: 0;
  }
}

.flow-editor-card-node {
  .card-header {
    padding: 2px 8px;
    text-transform: uppercase;
    font-size: 12px;
  }

  .icon-placeholder {
    border-radius: 4px;
  }
}

.flow-editor-start-node {
  width: 11px;
  height: 11px;
  background-color: $primary;
  border-radius: 50%;
}

.flow-editor-group-node {
  border-color: $primary;
  min-width: 377px;

  > .card-header {
    background-color: $primary;
  }

  > .card-body {
    padding: 30px 50px;
  }
}

.flow-editor-simple-node {
  border-color: $gray-200;

  > .card-header {
    background-color: $gray-200;
  }

  > .card-body {
    padding: 8px;
  }
}

.flow-editor-channel-node {
  border-color: $gray-700;

  > .card-header {
    background-color: $gray-700;
  }

  > .card-body {
    padding: 8px;
  }
}

.flow-editor-block-node {
  border-color: $gray-700;
  min-width: 377px;

  > .card-header {
    background-color: $gray-700;
  }

  > .card-body {
    padding: 30px 50px;
  }
}

.flow-editor-agent-node {
  min-width: 365px;
  @extend .border-primary-gradient;

  > .card-header {
    @extend .bg-primary-gradient;
  }

  > .card-body {
    padding: 14px 8px;
  }
}

.flow-editor-creator-node {
  padding: 2px;
}

.flow-editor-diamond-node,
.flow-editor-diamond-label {
  padding: 10px;

  > div {
    width: 50px;
    height: 50px;
    transform: rotate(45deg);

    > span {
      transform: rotate(-45deg);
    }
  }
}

.flow-editor-split-node {
  margin: 56px 0 56px;
}

.flow-editor-label-simple {
  margin-bottom: 28px;
}

// Labels

.flow-editor-edge-with-diamond-label {
  margin-bottom: 98px;
}
