.simplebar-content-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

[data-simplebar="init"] {
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
}

.simplebar-content {
  padding-top: 0 !important;

  .nav-title {
    margin-top: 0;
    padding: 16px 12px 8px;
    font-size: 12px;
    line-height: 16px;

    &:first-child {
      padding-top: 4px;
    }
  }

  .nav-item {
    line-height: 24px;
    margin-top: 0 !important;
  }
}

.sidebar-nav {
  --cui-sidebar-nav-link-active-color: var(--cui-primary);
  --cui-sidebar-nav-link-active-icon-color: var(--cui-primary);
}

